import * as React from "react"
import Img from "gatsby-image"
import "../scss/components/contentBlock.scss"

class ContentBlock extends React.Component {
  render() {
    const data = this.props.data
    const bg = this.props.bg
    const bgTest = bg !== null && bg !== undefined && bg !== ""

    return (
      <section
        className={
          bgTest === true ? "padding--none content-block__bg" : "padding--none"
        }
        style={{ backgroundImage: bgTest === true ? "url(" + bg + ")" : "" }}
      >
        <div
          className={`content-block ${
            data.imagePosition === "Left" ? "content-block--reverse" : ""
          }`}
        >
          {/* Content */}
          <div className="content-block__content container flow--thin">
            <h2 className="content-block__title">{data.contentTitle}</h2>
            <p className="content-block__desc">
              {data.contentText.contentText}
              <br />
              <a 
                href={data.contentLink.linkUrl}
                target={data.linkNewTab && "_blank"} 
                rel={data.linkNewTab && "noopener noreferrer"}
              >
                {data.linkText}
              </a>
            </p>
          </div>
          {/* Image */}
          <div
            className="content-block__img-wrapper"
            style={{ height: 100, width: 100 }}
          >
            <Img
              alt={data.contentImage.description}
              height="100px"
              width="100px"
              fluid={data.contentImage.fluid}
              className="content-block__img"
            />
          </div>
        </div>
      </section>
    )
  }
}

export default ContentBlock
