import * as React from "react"
import get from "lodash/get"
import "../scss/components/benefitList.scss"

class BenefitList extends React.Component {
  render() {
    const list = get(this, "props.data.listClass")
    const listItems = get(this, "props.data.listItems")

    return (
      <ul className={"benefit-list " + list}>
        {listItems.map(item => (
          <li key={item.id} className="benefit-list-item">
            <img
              src={item.listItemImage.file.url}
              width="40"
              height="40"
              alt=""
              className="benefit-list-image"
            />
            <p className="benefit-list-desc">{item.listItemDescription}</p>
          </li>
        ))}
      </ul>
    )
  }
}

export default BenefitList
