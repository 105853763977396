import * as React from "react"
import { graphql } from "gatsby"
import get from "lodash/get"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Hero from "../../components/hero"
import CalloutBanner from "../../components/calloutBanner"
import Card from "../../components/card"
import ContentBlock from "../../components/contentBlock"
import BenefitList from "../../components/benefitList"

import "../../scss/pages/simpas-equipment.scss"

class SimpasEquipmentPage extends React.Component {
  componentDidMount() {
    // Adding &nbsp;
    let cardText = document.querySelectorAll(".card__desc, .callout--banner__headline, .callout--banner__desc"); 
    cardText.forEach(function(el) {
      el.innerHTML = el.innerHTML.replace('valves are open', "valves are&nbsp;open");
      el.innerHTML = el.innerHTML.replace('planting efficiency', "planting&nbsp;efficiency");
      el.innerHTML = el.innerHTML.replace('process post-planting', "process post&#8209;planting");
      el.innerHTML = el.innerHTML.replace('credit market', "credit&nbsp;market");
      el.innerHTML = el.innerHTML.replace('you plant', "you&nbsp;plant");
      el.innerHTML = el.innerHTML.replace('Trimble® Dealer', "Trimble®&nbsp;Dealer");
      el.innerHTML = el.innerHTML.replace('and convenience.', "and®&nbsp;convenience.");
    })

    // Replacing &reg; elements
    let regElements = document.querySelectorAll("main h2, main h3, main p, main .btn, .card__desc, .hero__title"); 
    regElements.forEach(function(el) {
      el.innerHTML = el.innerHTML.replace('®', "<sup>&reg;</sup>");
      el.innerHTML = el.innerHTML.replace('SmartCartridge containers are designed', "<br><br>SmartCartridge containers are designed");
    })
  }
  render() {
    // Component Content
    const heroContent = get(this, "props.data.contentfulHero")
    const equipmentCards = get(this, "props.data.contentfulList.listItems")
    const contentfulCalloutBanner = get(
      this,
      "props.data.contentfulCalloutBanner"
    )
    const contentBlockEasytoUse = get(this, "props.data.easyToUse")
    // const contentBlockHowItWorks = get(this, "props.data.howItWorks")
    // const contentBlockHowItWorksBg =
    //   contentBlockHowItWorks.backgroundImage.file.url
    const rowUnitImg = get(this, "props.data.rowUnitImg")
    const contentBenefitList = get(this, "props.data.equipmentBenefitsList")

    return (
      <Layout bodyClass="simpas-equipment">
        <Seo title="SIMPAS Equipment" />

        <section className="padding--none">
          <Hero data={heroContent} />
        </section>

        <section className="bg--gray" id="accurate">
          <div className="container text-center flow--thick">
            <h2>Benefits</h2>
            <p id="benefits-intro">
              The game-changing technology of the SIMPAS&reg; application
              system will transform the way you plant with the following:
            </p>
            <BenefitList data={contentBenefitList} />
            <p><strong>Learn how SIMPAS-applied Solutions™ Can Save You Money and Increase&nbsp;Yield</strong></p>
            <div className="text-center">
              <a className="btn" href="/contact-us">
                Connect with a Specialist
              </a>
            </div>
          </div>
        </section>

        <section
          className="how-it-works"
          style={{
            backgroundImage:
              "url(//images.ctfassets.net/ub0n40qw3m4r/6QxQiQGWZc1EobWw0If9B2/085d09b1053ff18e3995c54fb875743c/bg-howitworks.jpg)",
          }}
        >
          <div className="content-block">
            <div className="content-block__content container flow--thick">
              <h2>How It Works</h2>
              <p>
                SIMPAS is the next evolution in precision agriculture, allowing farmers to prescriptively apply up to three SIMPAS-applied Solutions™ (SaS™) in-furrow at the same time while planting. With SIMPAS, you pay only for as-applied product, so you can profitably and sustainably address pressure areas that would otherwise go untreated.
              </p>
              <p>
                SIMPAS also supports environmental stewardship with precise product application, fewer inputs per field, reduced worker exposure and forensic-quality traceability.
              </p>
            </div>
            <div className="content-block__img-wrapper">
              <div className="content-block__img gatsby-image-wrapper">
                <img alt={rowUnitImg.description} src={rowUnitImg.fluid.src} />
              </div>
            </div>
          </div>
        </section>

        <div className="bg--gray" id="easy-to-use">
          <ContentBlock data={contentBlockEasytoUse} />
        </div>

        <br />
        <br />
        <br />

        <section className="callout--cartridge bg--gray">
          <div className="container">
            <div className="row flow--sm">
              <div className="col-12 flow">
                <h2>SmartCartridge&reg; Container Technology</h2>
                <p>Our patented SmartCartridge® container technology delivers multiple SIMPAS-applied Solutions™ — granular or liquid — at the same time, with each container holding approximately 20 to 25 pounds of granular product or approximately 2.5 gallons of a liquid product. Each cartridge is equipped with an RFID-enabled Smart Tag™ that captures and transmits vital information from fill to the field and back — and at every stop along the way. Smart Tags also ensure each SmartCartridge is in the appropriate slot on the SIMPAS unit.<br /><br />After planting, simply return all the cartridges — regardless of how much product remains — to your retailer and pay only for the product used.
                  <br />
                  <a href="/simpas-applied-solutions/solutions/">Explore SIMPAS-applied Solutions&trade;</a>
                </p>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row flow--sm">
              {equipmentCards.map(item => {
                return (
                  <div className="col-12 col-md-4" key={item.id}>
                    <Card data={item} />
                  </div>
                )
              })}
            </div>
          </div>
        </section>

        <section className="bg--blue contact">
          <div className="text-center">
            <h3 className="h2">Ready to Change the Way You&nbsp;Plant?</h3>
            <p>To purchase a SIMPAS application system, contact a participating Trimble&reg; Dealer who will provide a quote and take your&nbsp;order.</p>
            <div className="container">
              <div className="adj-buttons">
                <div className="text-center">
                  <a 
                    className="btn" 
                    href="/equipment/equipment-costs/#/simpas">
                    Estimate Equipment Costs
                  </a>
                </div>
              </div>
              <div className="adj-buttons">
                <div className="text-center">
                  <a className="btn" href="/find-a-provider/#map-container">
                    Find a Dealer
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
export default SimpasEquipmentPage

export const SimpasEquipmentPageQuery = graphql`
  query SimpasEquipmentPageQuery {
    contentfulHero(heroTitle: { eq: "SIMPAS® Application System" }) {
      id
      heroTitle
      heroSubtitle
      heroDescription {
        heroDescription
      }
      backgroundImage {
        fluid(maxWidth: 1800, background: "#000", quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      foregroundImage {
        file {
          url
        }
        description
      }
      modalLink
      modalButtonText
    }
    easyToUse: contentfulContentBlock(
      contentBlockName: { eq: "Equipment - Easy to Use" }
    ) {
      id
      contentTitle
      contentText {
        contentText
      }
      contentLink {
        linkUrl
      }
      linkText
      linkNewTab
      imagePosition
      contentImage {
        fluid (quality: 100) {
          ...GatsbyContentfulFluid
        }
        description
      }
    }
    howItWorks: contentfulContentBlock(
      contentBlockName: { eq: "Equipment - How It Works" }
    ) {
      id
      backgroundImage {
        file {
          url
        }
      }
      contentTitle
      contentText {
        contentText
      }
      linkText
      imagePosition
      contentImage {
        fluid (quality: 100) {
          base64
          tracedSVG
          srcWebp
          srcSetWebp
        }
      }
      extraStyling
    }
    contentfulList(listName: { eq: "System Equipment List" }) {
      id
      listItems {
        ... on ContentfulCard {
          id
          cardImage {
            fluid (quality: 100) {
              ...GatsbyContentfulFluid
            }
          }
          cardTitle
          cardDescription {
            cardDescription
          }
        }
      }
    }
    contentfulCalloutBanner(contentful_id: { eq: "5IqAKP3nLdJ8Yr6RGTFgCm" }) {
      id
      backgroundColor
      calloutTitle
      calloutSubtitle
      linkText
      calloutLink {
        linkUrl
      }
    }
    equipmentBenefitsList: contentfulList(
      listName: { eq: "Equipment Benefits List" }
    ) {
      id
      listClass
      listItems {
        ... on ContentfulListItem {
          id
          listItemDescription
          listItemImage {
            file {
              url
            }
            description
          }
        }
      }
    }
    equipmentBenefitsMobileImg: contentfulAsset(
      title: { eq: "equipment-benefits-mobile" }
    ) {
      id
      description
      title
      fluid(maxWidth: 768, resizingBehavior: PAD, quality: 100) {
        ...GatsbyContentfulFluid
      }
    }
    equipmentBenefitsDesktopImg: contentfulAsset(
      title: { eq: "equipment-benefits" }
    ) {
      id
      description
      title
      fluid(maxWidth: 768, resizingBehavior: PAD, quality: 100) {
        ...GatsbyContentfulFluid
      }
    }
    rowUnitImg: contentfulAsset(title: { eq: "Row-Unit" }) {
      id
      description
      title
      fluid(maxWidth: 768, resizingBehavior: PAD, quality: 100) {
        ...GatsbyContentfulFluid
        src
      }
    }
  }
`
