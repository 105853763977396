import * as React from "react"
import "../scss/components/calloutBanner.scss"

class CalloutBanner extends React.Component {
  render() {
    const data = this.props.data
    const bgClass = data.backgroundColor
      ? "bg--" + data.backgroundColor.toLowerCase()
      : ""

    return (
      <section className={`callout--banner ${bgClass}`}>
        <div className="container text-center flow">
          {/* Callout Title */}
          {data.calloutTitle && (
            <h2 className="callout--banner__headline">{data.calloutTitle}</h2>
          )}
          {/* Callout Subtitle */}
          {data.calloutSubtitle && (
            <h3 className="h4 callout--banner__desc">{data.calloutSubtitle}</h3>
          )}
          {/* Callout Button */}
          {data.calloutLink.linkUrl && data.linkText && (
            <a href={data.calloutLink.linkUrl} className="btn">
              {data.linkText}
            </a>
          )}
        </div>
      </section>
    )
  }
}

export default CalloutBanner
